<template>
  <div class="loader-wrap">
    <div class="loader">
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>

export default {

  created() {
    const ad_source =  this.$route.params.source

    if (ad_source){
      localStorage.setItem('ad_source',ad_source)

      this.$router.push('/')
    }

  }

}

</script>
<style scoped>
.loader-wrap {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  z-index: 999;
  overflow: hidden
}

.loader div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  background: #fb7299;
  opacity: 0.5;
}

.loader div:nth-child(1) {
  -webkit-animation: loader-wrap 2s 0s infinite ease-in-out;
}

.loader div:nth-child(2) {
  -webkit-animation: loader-wrap 2s -1s infinite ease-in-out;
}

@-webkit-keyframes loader-wrap {
  0% {
    -webkit-transform: scale(1);
    opacity: 0.5;
  }

  50% {
    -webkit-transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0.5;
  }
}
</style>